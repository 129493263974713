import * as React from "react"
import { graphql } from 'gatsby'
import ComponentPDCF from "./../components/loadable/politicaconfidentialitate";

const Component = (props) => {
    return (
        <ComponentPDCF {...props} />
    )
};

export default Component;


export const query = graphql`
    query PoliticaConfidentilitate {
        prismicContact {
            id
            data {
              first_meeting_is_free
              therapeut_name {
                text
              }
              body {
                ... on PrismicContactDataBodyEmail {
                  id
                  items {
                    display
                    email {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyLocation {
                  id
                  items {
                    display
                    embeded_map {
                     raw
                    }
                    short_name {
                      text
                    }
                    address {
                      text
                    }
                    latitude {
                      text
                    }
                    longitude {
                      text
                    }
                    image_building {
                      gatsbyImageData
                      alt
                      url
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyPhone {
                  id
                  items {
                    display
                    phone_number {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyProgram {
                  id
                  primary {
                    program {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyWhasup {
                  id
                  items {
                    display
                    whatsup_phone_number {
                      text
                    }
                    whatsup_desktop_text {
                      text
                    }
                    whatsup_mobile_text {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
              }
              page_title {
                text
              }
              second_title {
                text
              }
              text_first_meeting_free {
                text
              }
            }
        }
    }
`
