import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/contact.scss'
import '../../styles/general.scss'
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect} from "react";
import Banner from "../Banner";
import {useLocation} from "@reach/router";

export default function ComponentPDCF({data}) {
    const prismicContact = data.prismicContact
    // in your cookie banner
    const location = useLocation()

    useEffect(() => {
        Mixpanel.track('Page-Legislatie');
    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/politicaconfidentialitate/",
        title: 'Politica de Confidentialitate, Cabinet psihologic Roxana S.',
        description: 'Politica de Confidentialitate - Cabinet psihologic Roxana Simionescu, psiholog atestat, Bucuresti, avize sedinte online anonimizare date utilizator,',
        url: "https://www.cabinet-psiho.ro/politicaconfidentialitate",
        metas: [
        ]
    }


    return (
        <div>
            <Seo title="Contact" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"legislatie"} prismicContact={prismicContact}/>

                <Banner />

                <Grid item sm={12} xl={12} lg={12} md={12}
                      className={` p-4 pl-64 pr-64 pt-64 w-full`}
                >
                    <div className={"flex flex-row justify-content-center align-middle align-items-center mb-8"}>
                        <div className={"heading_1 primary_heading_1_title color_green_light font-bold text_green_light_color font_size_xxl"} >
                            Politica Confidentialitate
                        </div>
                    </div>


                    <Grid container className={"w-full justify-content-center align-middle align-content-center"} direction={"row"}>
                        <div className={"w-full flex flex-column justify-content-center align-middle align-content-center generic_box_max_width"} >
                            <p>
                                Politica de prelucrare a datelor cu caracter personal
                            </p>

                            <p>
                                Politica de confidentialitate are ca scop furnizarea de informatii cu privire la colectarea, utilizarea si divulgarea catre terti a datelor cu caracter personal, stranse prin intermediul site-ului https://cabinet-psiho.ro reprezentat prin Roxana Simionescu, psiholog si psihoterapeut, atestat de Colegiul Psihologilor din Romania si avand Cod personal  21 308 in Registrul unic al psihologilor cu drept de libera practica din Romania.
                            </p>

                            <p>
                                În conformitate cu dispoziţiile Regulamentului (UE) 2016/679 al Parlamentului European şi al Consiliului din 27 aprilie 2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor), Roxana Simionescu, psiholog are obligaţia de a prelucra în condiţii de siguranţă şi numai pentru scopurile specificate mai jos, datele personale pe care ni le furnizaţi despre dumneavoastră, un membru al familiei dumneavoastră ori o altă persoană.
                            </p>

                            <p>
                                Gabriela Simionescu, psiholog se obligă să păstreze confidenţialitatea datelor personale furnizate de dumneavoastră prin intermediul site-ului https://cabinet-psiho.ro prin trimiterea unui email, apelarea telefonului, zoho chat, whatsup, furnizate prin intermediul site-ului.
                            </p>

                            <p>
                                Prin intermediul acestor termeni si conditii doresc sa va explic ce date prelucrez, de ce le prelucrez si ce fac cu ele.
                                Confidentialitatea datelor dvs. personale este importanta pentru mine si ma oblig sa asigur protectia lor fata de terti.
                                Nu voi instraina/vinde niciodata datele dvs. de contact: adrese de email si numere de telefon.
                                Sunt constienta ca datele cu caracter personal va apartin, de aceea fac tot efortul ca ele sa fie stocate in siguranta, pe server securizat si sa fie prelucrate cu atentie.
                            </p>

                            <p>Ce informatii colectam si in ce scop</p>
                            <p>
                            Datele cu caracter personal sunt stocate exclusiv pentru a facilita procesul de comunicare si a va putea raspunde la mesaje. Datele colectate sunt:
                            </p>
                            <ol>
                                <li>- Numele si prenumele</li>
                                <li>- Numarul de telefon</li>
                                <li>- Adresa de email</li>
                            </ol>
                        </div>
                    </Grid>
                </Grid>

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )
}

